import Cookies from 'js-cookie'

/**
 * Describes the valid token keys for apps in our domain.
 */
const TokenKeys = {
  vojo: 'vojo_token',
  eduk: 'eduk_token',
  orkestro: 'orkestro_token'
}

// TODO: Remove the hard-coded domain property and add a DOMAIN env variable
const cookieOptions = process.env.NODE_ENV === 'development'
  ? undefined
  : { domain: 'eduk.com.br' }

/**
 * In-memory cache of the token. We don't use browser storage because we don't
 * want to persist tokens that won't be accessible to the other apps in the
 * domain
 */
const inMemoryTokenStorage = {
  orkestro_token: null,
  vojo_token: null,
  eduk_token: null
}

/**
 * @param key Which token to get. Defaults to this app.
 * @returns The specified token
 */
export function getAuthToken (key = TokenKeys.vojo) {
  return Cookies.get(key) || inMemoryTokenStorage[key]
}

/**
 * @param token The new token value
 * @param key Which token to alter. Defaults to this app.
 */
export function setAuthToken (token, key = TokenKeys.vojo) {
  Cookies.set(key, token, cookieOptions)
  inMemoryTokenStorage[key] = token
}

/**
 * @param key Which token to remove. Defaults to this app.
 */
export function removeAuthToken (key = TokenKeys.vojo) {
  Cookies.remove(key, cookieOptions)
  inMemoryTokenStorage[key] = null
}

/**
 * Removes all auth tokens found in TokenKeys.
 */
export function removeAuthTokens () {
  Object.values(TokenKeys).forEach(removeAuthToken)
}
