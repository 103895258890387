export const APPLICATION = (id) => id ? `/candidatura/${id}` : '/candidatura/:id'
export const ASSESSMENT = (id) => id ? `/avaliacao/${id}` : '/avaliacao/:id'
export const ASSESSMENT_SCORE = '/pontuacao'
export const COUPONS = '/cupons'
export const COURSES = '/cursos'
export const FORGOT_PASSWORD = '/esqueceu-senha'
export const FORMS = (id) => id ? `/forms/${id}` : '/forms/:id'
export const HOMEPAGE = '/'
export const JOB_DETAIL = (slug) => slug ? `/vaga/${slug}` : '/vaga/:slug'
export const JOBS_ENTERPRISING = '/renda'
export const JOBS_LIST = '/vagas'
export const LOGIN = '/entrar'
export const MY_ACCOUNT = '/minha-conta'
export const MY_APPLICATIONS = '/minhas-candidaturas'
export const ONBOARDING = '/caminho'
export const RECOVER_PASSWORD = '/new-password'
export const REGISTER = '/cadastro'
export const COMPANY_JOB = (slug) => slug ? `/company/${slug}` : '/company/:slug'
export const FAQ_REMOVE_ACCOUNT = 'https://meajuda.eduk.com.br/pt-br/category/cancelamento-1w5x1c1/'
export const FAQ_EDIT_ACCOUNT = 'https://meajuda.eduk.com.br/pt-br/'
export const VOJO_URL = 'https://vojo.com.br/'
export const RENDA_EDUK = 'https://renda.eduk.com.br/'
export const FAQ_EDUK = 'https://meajuda.eduk.com.br/pt-br/'
export const HEADER_EDUK_LINK = 'https://gerandorenda.eduk.com.br/'
export const EDUK_TERMS = 'https://beta.eduk.com.br/termos-de-uso'
