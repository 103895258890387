import React, { Component } from 'react'

import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import { Trans } from '@lingui/macro'
import {
  CarouselSelectorEduk,
  Container,
  FooterEduk,
  TypographyEduk,
  StickyToast,
  themes,
  BannerImageEduk,
  ButtonEduk,
  SpinnerEduk,
  CourseSuggestion
} from '@mindlab-org/component-library'
import QueryString from 'qs'
import './JobDetail.sass'

import CustomHeader from '../../components/CustomHeader'
import * as routes from '../../routes/routes'
import { routesEduk } from '../../routes/routesEduk'
import { removeArrayDuplicates } from '../../utils/arrays/arrays'
import { actions } from '../../redux/actions/actions'
import setAuthToken from '../../utils/Authentication/setAuthToken'

class JobDetail extends Component {
  state = {
    selectedState: null
  }

  componentDidMount () {
    const token = QueryString.parse(this.props.location.search)['?token']
    token && setAuthToken(token)
    token && this.props.authorizationSet()

    const { isAuth } = this.props.authorization
    isAuth && this.props.applicationListSet()
  
    const {slug} = this.props.match.params
    this.props.jobDetailSet({ slug })
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.application.applicationCreated !== this.props.application.applicationCreated &&
      this.props.application.applicationCreated.success && !this.props.application.applicationCreated.isLoading
    ) {
      this.props.history.push(routes.APPLICATION(this.props.application.applicationCreated.data.applicationId))
    }

    if (prevProps.jobDetail.data !== this.props.jobDetail.data && this.props.jobDetail.success) {
      this.props.jobSet({ role: this.props.jobDetail.data.id, state: this.props.jobDetail.data.locations[0].states[0] })
    }
  }

  componentWillUnmount () {
    this.props.jobClear()
  }

  setRedirectCookies (slug) {
    const cookieOptions = process.env.NODE_ENV === 'development'
      ? undefined
      : { domain: 'eduk.com.br' }

    const redirectAfterLogin = `${process.env.REACT_APP_VOJO_URL}${routes.JOB_DETAIL(slug)}`
    Cookies.set('redirectAfterLogin', redirectAfterLogin, cookieOptions)
  }

  handleApplication = () => {
    const { isAuth } = this.props.authorization
    const { slug, id: jobRoleId } = this.props.jobDetail.data

    if (!isAuth) {
      this.setRedirectCookies(slug)
      window.open(routesEduk.login, "_self")
    } else {
      this.props.applicationSubmitSet({ jobRoleId })
    }
  }

  createCarouselItemsFromLocations = () => {
    const { jobDetail } = this.props
    return jobDetail.data.locations[0].states.map(state => ({
      id: state,
      title: state
    }))
  }

  createCitiesFromLocation = () => {
    const { jobs } = this.props
    const { selectedState } = this.state

    const createCitiesArray = () => {
      let citiesArray = []
      let citiesString = ''

      jobs.data.map(job => {
        // MAPS 0 (BRAZIL) location of each job
        job.locations[0].states.map(state => {
          // Checks for states with same initial as 'selectedState' on each job
          if (state.initials === selectedState) {
            // Pushes all cities that belongs
            state.cities.map(city => {
              citiesArray.push(city)
              return city // default return
            })
          }
          return state // default return
        })
        return job // default return
      })

      citiesArray = removeArrayDuplicates(citiesArray)
      citiesArray.map((city, index) => {
        citiesString = citiesString.concat(
          index === 0
            ? city
            : `, ${city}`
        )
        return city // default return
      })

      return citiesString
    }

    return jobs.data && selectedState
      ? createCitiesArray()
      : ''
  }

  fetchSelectedStateData = (state) => {
    const { jobDetail } = this.props

    const requestData = {
      role: jobDetail.data.id,
      state: state
    }

    this.props.jobSet(requestData)
  }

  renderRequirementList = () => {
    const { jobDetail } = this.props

    return (
      <div className="JobDetail__Info__Block__List">
        <ul className="JobDetail__List">
          {
            jobDetail.data.requirements.map(requirement => (
              <li key={requirement.id}>
                <TypographyEduk
                  fontSize="14px"
                  color={themes.eduk.colors.lateBlack}>
                  {requirement.description}
                </TypographyEduk>
              </li>
            ))
          }
        </ul>
      </div>
    )
  }

  onClickGoBack = () => window.open(routesEduk.jobList, "_self")

  renderButton = () => {
    const { applicationList, jobDetail } =  this.props
    const applicationFound = applicationList.data.find(({jobRole}) => jobDetail.data.id === jobRole.id)

    const onButtonClick = () => {  
      if (!applicationFound) return this.handleApplication()
      return this.props.history.push(routes.APPLICATION(applicationFound.id))
    }
  
    const buttonText = {
      "Mercado Envios EXTRA": "ME CADASTRAR",
      "Oddie": "ME CADASTRAR",
    }[jobDetail.data.company?.socialName]

    return (
      <ButtonEduk
        backgroundColor={themes.eduk.colors.primaryColor}
        hoverColor={themes.eduk.colors.primaryColor}
        id="candidatar"
        name="candidatar"
        padding={"14px 0px"}
        onButtonClick={onButtonClick}
        textColor={themes.eduk.colors.blackColor}
      >
        <TypographyEduk
          type={"title"}
          fontSize={"16px"}
          color={themes.eduk.colors.whiteColor}
        >
          {applicationFound ? 'FINALIZAR MINHA CANDIDATURA' : (buttonText || 'ME CANDIDATAR')}
        </TypographyEduk>
      </ButtonEduk>
    )
  }

  requirementsTitle = () => {
    const { jobDetail } =  this.props

    return {
      "Mercado Envios EXTRA":
        "Requisitos Mínimos Para Prestação de Serviços ao Mercado Envios",
    }[jobDetail.data.company.socialName] || "Requisitos mínimos"
  }

  render () {
    const { jobDetail, jobs } = this.props
    const { application } = this.props

    return (
      <Container maxWidth="full">
        <Container maxWidth="full">
          <CustomHeader history={this.props.history} />
          {
            jobDetail.error && (
              <div style={{
                display: 'block',
                width: '100%',
                zIndex: '100'
              }}>
                <StickyToast show>
                  <TypographyEduk
                    color={themes.eduk.colors.redColor}
                    tag="span"
                    type="default">
                    {jobDetail.error.message}
                  </TypographyEduk>
                </StickyToast>
              </div>
            )
          }
          {
            application.applicationCreated.error && (
              <div style={{
                display: 'block',
                width: '100%',
                zIndex: '100'
              }}>
                <StickyToast show>
                  <TypographyEduk
                    color={themes.eduk.colors.redColor}
                    tag="span"
                    type="default">
                    {'O usuário já está cadastrado para essa oportunidade.'}
                  </TypographyEduk>
                </StickyToast>
              </div>
            )
          }

        </Container>
        <Container maxWidth="full">
          <div className="JobDetail">
            {
              jobDetail.isLoading ? (
                <SpinnerEduk spinnerArea="90vh" />
              ) : (
                <>
                  <Container maxWidth="full-desktop">
                    <div className="JobDetail__Banner">
                      {jobDetail.data.imageUrl && <BannerImageEduk
                        {...jobDetail.data}
                        onClickGoBack={this.onClickGoBack}
                      />}
                      <div className="JobDetail__Banner__Info">
                        <div />
                        {
                          jobDetail.data.newsTag && (
                            <div className="JobDetail__Banner__Info__IsNew JobDetail__Banner__Info__Tag">
                              <TypographyEduk
                                fontSize="9px"
                                color={themes.eduk.colors.whiteColor}>
                                {'NOVIDADE'}
                              </TypographyEduk>
                            </div>
                          )
                        }
                      </div>
                      <div className="JobDetail__Banner__Button">
                        <Container maxWidth="desktop">
                          {this.renderButton()}
                        </Container>
                      </div>
                    </div>
                  </Container>
                  <Container maxWidth="desktop">
                    {jobDetail.data.company && <div className="CompanyName">
                      <TypographyEduk
                        fontSize="18px"
                        fontWeight="700"
                        fontFamily="'Montserrat', sans-serif"
                      >
                        {jobDetail.data.company?.socialName}
                      </TypographyEduk>
                    </div>}
                    <div className="JobDetail__Info">
                      {
                        jobDetail.data.requirements &&
                          <div className="JobDetail__Info__Block">
                            <div className="JobDetail__Info__Block__Title">
                              <TypographyEduk
                                fontSize="18px"
                                fontWeight="700"
                                fontFamily="'Montserrat', sans-serif"
                                color={themes.eduk.colors.lateBlack}
                              >
                                <Trans id={this.requirementsTitle()} />
                              </TypographyEduk>
                            </div>
                            {this.renderRequirementList()}
                          </div>
                      }
                      {
                        jobDetail.data.locations && (
                          <div className="JobDetail__Info__Block">
                            <div className="JobDetail__Info__Block__Title">
                              <TypographyEduk
                                fontSize="18px"
                                fontWeight="700"
                                fontFamily="'Montserrat', sans-serif"
                                color={themes.eduk.colors.lateBlack}>
                                <Trans id="Locais disponíveis" />
                              </TypographyEduk>
                            </div>
                            <div className="JobDetail__Info__Block__Subtitle">
                              <TypographyEduk
                                fontSize="12px"
                                color={themes.eduk.colors.lateBlack}>
                                <Trans id="Toque no estado para ver as cidades" />
                              </TypographyEduk>
                            </div>
                            <div>
                              <CarouselSelectorEduk
                                isLoading={this.props.jobs.isLoading}
                                description={this.createCitiesFromLocation()}
                                items={this.createCarouselItemsFromLocations()}
                                onClickItem={(item) => {
                                  this.setState({ selectedState: item.id })
                                  this.fetchSelectedStateData(item.id)
                                }}
                              />
                            </div>
                          </div>
                        )
                      }
                      {
                        jobs.data.map(job => (
                          <div key={job.id}>
                            <div className="JobDescription">
                              <TypographyEduk
                                fontSize="14px"
                                fontWeight="400"
                              >
                                {job.description}
                              </TypographyEduk>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </Container>
                </>
              )
            }
          </div>
        </Container>
        <Container maxWidth="full">
          <FooterEduk />
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  application: state.application,
  authorization: state.authorization,
  jobs: state.jobs,
  jobDetail: state.jobDetail,
  applicationList: state.applicationList,
})

const mapDispatchToProps = dispatch => ({
  applicationSubmitSet: data => dispatch(actions.applicationSubmitSet(data)),
  jobClear: () => dispatch(actions.jobClear()),
  jobDetailSet: data => dispatch(actions.jobDetailSet(data)),
  jobSet: data => dispatch(actions.jobSet(data)),
  applicationListSet: data => dispatch(actions.applicationListSet(data)),
  authorizationSet: () => dispatch(actions.authorizationSet())
})

export default connect(mapStateToProps, mapDispatchToProps)(JobDetail)


